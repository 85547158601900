/* src/app/components/links-toolbar/links-toolbar.component.scss */
.links-container {
  border: 0.2em solid #c6e7ff;
  max-width: 30em;
  margin: 0 auto;
}
.links-container .links-title {
  text-align: center;
  text-transform: uppercase;
  color: #001e2d;
  font-size: 0.8em;
  background-color: #c6e7ff;
  padding: 0.2em 0;
}
.links-container ul {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 3em;
}
.links-container ul ::ng-deep > li {
  padding: 0 1em;
}
/*# sourceMappingURL=links-toolbar.component.css.map */
