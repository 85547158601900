<div class="page-content">
    <ul>
        <li>
            <a href="mailto:alex@alexazhu.com">Email</a>
        </li>
        <li>
            <a href="https://github.com/azhu2">Github</a>
        </li>
        <li>
            <a href="https://linkedin.com/in/alextzhu">Linkedin</a>
        </li>
    </ul>
</div>