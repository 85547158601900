/* src/app/pages/home/home.component.scss */
:host {
  display: flex;
  flex-direction: column;
  height: 100%;
}
:host .img-splash {
  flex: 0 0 auto;
}
:host .page-content {
  text-align: center;
  padding: 1em 5em;
  flex: 1 1 auto;
}
:host .about {
  color: #72bc6d;
  font-size: 0.8em;
  font-style: italic;
  text-align: center;
  flex: 0 0 auto;
}
/*# sourceMappingURL=home.component.css.map */
