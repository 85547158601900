/* src/app/components/project-menu/project-menu.component.scss */
::ng-deep .cdk-overlay-pane .mat-mdc-menu-panel {
  background: #c6e7ff;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
::ng-deep .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content {
  padding: 0;
  overflow-y: hidden;
}
::ng-deep .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content a {
  display: block;
  background: #c6e7ff;
  color: #001e2d;
  min-width: 12em;
  margin: 0;
  padding: 0.5em 1.5em;
  display: flex;
}
::ng-deep .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content a span.mat-button-wrapper {
  margin-right: auto;
}
/*# sourceMappingURL=project-menu.component.css.map */
