<div class="page-content">
    <p>
        Android P (9.0, API level 28) introduced a change to permissions that broke existing call blocker apps. Given the rise of robocallers with spoofed numbers, I wrote a new app for personal use that can block all calls coming from the same prefix (useful when your area code doesn't match where you live).
    </p>
    <p>
        The app is currently still feature incomplete but does have the basic functionality of maintaining a blocklist and blocking calls appropriately.
    </p>
</div>

<app-links-toolbar>
    <li><a href="https://github.com/azhu2/android-call-blocker" matTooltip="Github source"><img class="img-icon" src="../../../assets/github.svg" /></a></li>
</app-links-toolbar>