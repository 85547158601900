/* src/app/app.component.scss */
mat-toolbar#nav-bar {
  padding: 0;
  flex: 0 0 2em;
  display: flex;
  flex-wrap: wrap;
}
mat-toolbar#nav-bar ::ng-deep a[mat-flat-button],
mat-toolbar#nav-bar app-project-menu {
  flex: 1 0 5em;
  height: 100%;
}
mat-toolbar#nav-bar ::ng-deep a[mat-flat-button] {
  border-radius: 0;
  padding: 0;
  margin: 0;
  display: flex;
}
mat-toolbar#nav-bar ::ng-deep a[mat-flat-button] span.mat-button-wrapper {
  margin: auto;
}
mat-toolbar#nav-bar ::ng-deep a[mat-flat-button].accent {
  background-color: #246c27;
}
#page-container {
  flex: 1 1 auto;
  min-width: 50vw;
  max-width: 80em;
  margin: 0 auto;
  padding: 1em;
  border-width: 0 1px;
}
/*# sourceMappingURL=app.component.css.map */
