/* src/app/pages/resume/resume.component.scss */
.pdf-viewer {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.pdf-viewer object {
  flex: 1 1;
}
a {
  display: block;
  text-align: center;
  color: #72bc6d;
  font-size: 0.8em;
}
/*# sourceMappingURL=resume.component.css.map */
